<template>
  <div>
    <v-autocomplete
      outlined
      v-model="selected"
      :label="label"
      loading="filterEnginesLoading"
      :items="userGroupsList"
      :item-text="item_text"
      :item-value="item_value"
      :menu-props="menu_props"
      :clearable="clearable_filter"
      :error-messages="errorMessages"
      :hide-details="hideDetails"
      :cache-items="cache_items"
      ref="autocompleteCompanies"
      :search-input="filterSearch"
    >
      <template v-slot:append-item>
        <div
          v-if="lastPageOfgetUserGroupsListComputed"
          v-observe-visibility="{
            callback: visibilityChanged,
          }"
          class="selectPagination"
        >
          <span class="pa-2">Loading more items ...</span>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "CompanyFilter",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    clearable_filter: {
      type: Boolean,
      default: true,
    },
    menu_props: {
      type: Object,
      default: () => ({ maxHeight: 304 }),
    },
    item_text: {
      type: String,
      default: "name",
    },
    item_value: {
      type: String,
      default: "_key",
    },
    label: {
      type: String,
      default: "Filter by company",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [Array],
      default: () => {
        return []
      },
    },
    findActiveElement: {
      type: String,
      default: "",
    },
    dialog: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    selected: "",
    filterSearch: "",
    filterSearchD: "",
    initSearch: "",
    selectPagination: {
      p: 1,
      pp: 500,
    },
    cache_items: true,
    userGroupsList: [],
    userGroupsCount: 0,
  }),

  mounted() {
    this.fetchGroupsList()
    this.initFilter()
  },

  watch: {
    dialog(val) {
      if (val) {
        if (this.findActiveElement) this.findGroup(this.findActiveElement)
      } else {
        this.initSearch = ""
        this.filterSearch = ""
        this.selected = ""
      }
    },
    findActiveElement: {
      handler(val) {
        if (val) this.findGroup(val)
      },
      deep: true,
      immediate: true,
    },
    selected(val) {
      this.$emit("update:selected", val)
    },
    filterSearch() {
      this.selectPagination.p = 1
      this.fetchGroupsList()
    },
  },

  methods: {
    visibilityChanged(e) {
      if (this.lastPageOfgetUserGroupsListComputed) {
        e && this.nextPage()
      }
    },
    nextPage() {
      let maxPages = Math.ceil(this.userGroupsCount / this.selectPagination.pp)
      if (this.selectPagination.p <= maxPages - 1) {
        this.filterEnginesLoading = true
        this.selectPagination.p += 1
        this.fetchGroupsList()
      }
      this.filterEnginesLoading = false
    },

    fetchGroupsList: function () {
      let self = this
      if (this.$can("read", "groups")) {
        let fetchData = {
          params: {
            p: this.selectPagination.p,
            pp: this.selectPagination.pp,
          },
        }
        if (this.initSearch || this.filterSearch) {
          fetchData.params.search = this.filterSearch || this.initSearch
        }
        self.$store.dispatch("getUserGroups", fetchData).then((receive) => {
          self.initSearch = ""
          if (!fetchData.search) {
            self.userGroupsCount = receive.full_count
          }
          self.userGroupsList = receive.result
        })
      }
    },
    findGroup(_key) {
      let self = this
      this.$nextTick(() => {
        self.$refs.autocompleteCompanies.cachedItems = []
        if (_key) {
          let finded = self.userGroupsList.find((g) => g._key === _key)
          if (!finded) {
            self.$store.dispatch("getUserGroup", _key).then((response) => {
              self.userGroupsList.unshift(response)
              self.selected = _key
            })
          } else {
            self.selected = _key
          }
        }
      })
    },
    initFilter() {
      const { company } = this.$route.query
      if (company) {
        this.findGroup(company)
      }
    },
  },

  computed: {
    lastPageOfgetUserGroupsListComputed() {
      return (
        this.userGroupsCount -
          this.selectPagination.p * this.selectPagination.pp >
        0
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.selectPagination {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.3);

  button,
  span {
    flex: 0 0 auto;
  }
}
</style>
