<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-card-title>Locations</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          to="/store/location/create"
          v-if="
            $can('create', 'Superadmin') || $can('create', 'engines_locations')
          "
          data-testid="btn-create-location"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <DataTableExtended
          ref="refTableEngineLocations"
          :headers="headers"
          :items="engineLocationsList"
          sortable
          class="elevation-0 border pt-3"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          v-on:init-table-data="getEngineLocationsList"
          :server-items-length="engineLocationsCount"
          keep="_key,name"
          data-testid="table-locations"
        >
          <template v-slot:top>
            <v-row class="px-3">
              <v-col
                cols="12"
                md="3"
                :offset="9"
                class="text-right"
                v-if="$can('read', 'Superadmin')"
              >
                <CompanyFilter
                  :hideDetails="true"
                  v-on:update:selected="onCompanyFilterChange($event)"
                  keep="_key,name"
                  data-testid="filter-company"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.data.name`]="{ item }">
            <span data-testid="location-name">
              {{ item.name }}
            </span>
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <div
              class="text-center d-flex align-center justify-space-around"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-tooltip
                top
                v-if="
                  $can('update', 'Superadmin') ||
                  $can('update', 'engines_locations')
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.edit"
                    v-on="on"
                    :to="`/store/location/edit/${item._key}`"
                    class="mx-1"
                    data-testid="btn-edit-location"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit location</span>
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  $can('delete', 'Superadmin') ||
                  $can('delete', 'engines_locations')
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.delete"
                    v-on="on"
                    @click="deleteEngineLocation(item)"
                    class="mx-1"
                    data-testid="btn-delete-location"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete location</span>
              </v-tooltip>
            </div>
          </template>
        </DataTableExtended>
      </v-card-text>
      <Confirm ref="confirmDialog"></Confirm>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Confirm from "@/components/Confirm"
import DataTableExtended from "@/components/table/DataTableExtended"
import CompanyFilter from "@/components/filters/CompanyFilter.vue"

export default {
  components: {
    Confirm,
    DataTableExtended,
    CompanyFilter,
  },

  data: () => ({
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: true,
      },
      {
        text: "",
        value: "menu",
        align: "center",
        sortable: false,
        class: "th-clear-padding",
        width: 100,
      },
    ],
    filterByCompany: null,
  }),

  watch: {
    filterByCompany: function () {
      this.getEngineLocationsList()
    },
  },

  methods: {
    onCompanyFilterChange(event) {
      this.filterByCompany = event
    },
    getEngineLocationsList() {
      let self = this
      let getParams = self.$refs.refTableEngineLocations.getTableServerParams()
      if (self.filterByCompany && self.isSuperUser)
        getParams.group_key = self.filterByCompany
      self.$store.dispatch("getEngineLocationsList", { params: getParams })
    },
    deleteEngineLocation(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete this location?", {
          color: "red darken-4",
          width: 330,
        })
        .then((confirm) => {
          if (confirm) {
            self.$store.dispatch("saveTableLoaderAction", true)
            self.$store
              .dispatch("deleteEngineLocation", item)
              .then(({ data }) => {
                self.getEngineLocationsList()
                self.$store.dispatch("saveTableLoaderAction", false)
              })
          }
        })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      engineLocationsList: "engineLocationsList",
      engineLocationsCount: "engineLocationsCount",
      isSuperUser: "isSuperUser",
    }),
  },
}
</script>
