var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-toolbar',[_c('v-card-title',[_vm._v("Locations")]),_c('v-spacer'),(
          _vm.$can('create', 'Superadmin') || _vm.$can('create', 'engines_locations')
        )?_c('v-btn',{attrs:{"color":_vm.Pallette.actionButtons.newItem,"fab":"","dark":"","absolute":"","bottom":"","right":"","small":!_vm.$vuetify.breakpoint.smAndUp,"to":"/store/location/create","data-testid":"btn-create-location"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-card-text',[_c('DataTableExtended',{ref:"refTableEngineLocations",staticClass:"elevation-0 border pt-3",attrs:{"headers":_vm.headers,"items":_vm.engineLocationsList,"sortable":"","loading":_vm.showTableLoader,"no-data-text":_vm.showTableLoader ? 'Loading…' : 'No data available',"server-items-length":_vm.engineLocationsCount,"keep":"_key,name","data-testid":"table-locations"},on:{"init-table-data":_vm.getEngineLocationsList},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-3"},[(_vm.$can('read', 'Superadmin'))?_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"3","offset":9}},[_c('CompanyFilter',{attrs:{"hideDetails":true,"keep":"_key,name","data-testid":"filter-company"},on:{"update:selected":function($event){return _vm.onCompanyFilterChange($event)}}})],1):_vm._e()],1)]},proxy:true},{key:`item.data.name`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"location-name"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.menu`,fn:function({ item }){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"text-center d-flex align-center justify-space-around"},[(
                _vm.$can('update', 'Superadmin') ||
                _vm.$can('update', 'engines_locations')
              )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":_vm.Pallette.actionButtons.edit,"to":`/store/location/edit/${item._key}`,"data-testid":"btn-edit-location"}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit location")])]):_vm._e(),(
                _vm.$can('delete', 'Superadmin') ||
                _vm.$can('delete', 'engines_locations')
              )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":_vm.Pallette.actionButtons.delete,"data-testid":"btn-delete-location"},on:{"click":function($event){return _vm.deleteEngineLocation(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete location")])]):_vm._e()],1):_vm._e()]}}],null,true)})],1),_c('Confirm',{ref:"confirmDialog"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }